.App {
  background: url(./assets/background.jpeg);
  width: 100%;
  height: 100vh;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App_fullHeight {
  background: url(./assets/background.jpeg);
  width: 100%;
  height: 100vh;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_container {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 75%;
  height: max-content;
  /* height: 75vh; */
  overflow: scroll;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  /* position: relative; */
}

@media screen and (max-width: 900px) {
  .main_container {
    width: 95%;
    height: max-content;
    margin: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .container {
    padding: 10px;
  }
  .App {
    height: max-content;
  }
}

@media screen and (min-width: 90px) {
  .container {
    padding: 0;
  }
}
