.logo {
  position: absolute;
  max-height: 150px;
  max-width: 200px;
  margin-top: -50px;
}

.logo_container {
  display: flex;
  flex: 1 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100px;
  justify-self: "center"
}

@media screen and (min-width: 700px) {
  .logo {
    margin-top: -50px;
  }
  .logo_container {
    height: 125px;
  }
}
