@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Cabinet_logo__3GFrU {
  position: absolute;
  max-height: 150px;
  max-width: 200px;
  margin-top: -50px;
}

.Cabinet_logo_container__1uOi5 {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100px;
  justify-self: "center"
}

@media screen and (min-width: 700px) {
  .Cabinet_logo__3GFrU {
    margin-top: -50px;
  }
  .Cabinet_logo_container__1uOi5 {
    height: 125px;
  }
}

.Button_button_container__2SqCS {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  background: linear-gradient(180deg, #49adff 52.39%, #1395ff 52.4%);
  border-radius: 4px;
  height: 56px;
}

.Button_button_disable_container__AkvpQ {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: rgba(211, 211, 211, 1);
  border-radius: 4px;
  height: 56px;
  pointer-events: none;
  cursor: not-allowed;
}

.Button_button_text__3f7Vu {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  margin: 0;
}

.Button_icon__23JL_ {
  /* padding: 12px 0 12px 16px; */
  padding-right: 16px;
}

.Login_text_container__1GUGS {
  padding-bottom: 40px;
  padding-top: 40px;
}
.Login_text__yxCrE {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;

  text-align: center;
  letter-spacing: 0.03em;

  color: #1395ff;
  margin: 0;
}

.Login_text_black__2_Gcf {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;

  text-align: center;
  letter-spacing: 0.03em;

  color: black;
  margin-top: 0;
}

.Login_button_container__1QRSP {
  display: flex;
  justify-content: center;
}

.App_App__16ZpL {
  background: url(/static/media/background.261abdf2.jpeg);
  width: 100%;
  height: 100vh;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App_App_fullHeight__xSBKM {
  background: url(/static/media/background.261abdf2.jpeg);
  width: 100%;
  height: 100vh;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App_main_container__33YI1 {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 75%;
  height: -webkit-max-content;
  height: max-content;
  /* height: 75vh; */
  overflow: scroll;
}

.App_container__1MQN3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  /* position: relative; */
}

@media screen and (max-width: 900px) {
  .App_main_container__33YI1 {
    width: 95%;
    height: -webkit-max-content;
    height: max-content;
    margin: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .App_container__1MQN3 {
    padding: 10px;
  }
  .App_App__16ZpL {
    height: -webkit-max-content;
    height: max-content;
  }
}

@media screen and (min-width: 90px) {
  .App_container__1MQN3 {
    padding: 0;
  }
}

.Logout_text_container__19qzV {
  padding-bottom: 50px;
  padding-top: 50px;
}
.Logout_text__2D7Uo {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;

  text-align: center;
  letter-spacing: 0.03em;

  color: #1395ff;
  margin: 0;
}

.Logout_text_black__1E6YU {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;

  text-align: center;
  letter-spacing: 0.03em;

  color: black;
  margin-top: 0;
}

.Logout_button_container__s2G-x {
  display: flex;
  justify-content: center;
}

.Logout_wallet_address__3V07Y {
  background-color: #f2fbff;
  border-radius: 6px;
}

.Logout_wallet_container__1sEoW {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.Logout_address__2LfmL {
  padding: 10px;
  margin: 0;
  
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  /* Black */

  color: #000000;
}

.RegisterInvestor_logo__29FF5 {
  position: absolute;
  max-height: 150px;
  max-width: 200px;
  margin-top: -50px;
}

.RegisterInvestor_logo_container__3WDrr {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100px;
  justify-self: "center"
}

@media screen and (min-width: 700px) {
  .RegisterInvestor_logo__29FF5 {
    margin-top: -50px;
  }
  .RegisterInvestor_logo_container__3WDrr {
    height: 125px;
  }
}

.DevestInvestor_logo__5L3RM {
  position: absolute;
  max-height: 150px;
  max-width: 200px;
  margin-top: -50px;
}

.DevestInvestor_logo_container__pd2Sz {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100px;
  justify-self: "center"
}

@media screen and (min-width: 700px) {
  .DevestInvestor_logo__5L3RM {
    margin-top: -50px;
  }
  .DevestInvestor_logo_container__pd2Sz {
    height: 125px;
  }
}

