.text_container {
  padding-bottom: 40px;
  padding-top: 40px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
.text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;

  text-align: center;
  letter-spacing: 0.03em;

  color: #1395ff;
  margin: 0;
}

.text_black {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;

  text-align: center;
  letter-spacing: 0.03em;

  color: black;
  margin-top: 0;
}

.button_container {
  display: flex;
  justify-content: center;
}
